var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"wr_front-end-720e6c62b0c9beee580bb7e412f1cfc65106f5a7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const isProd = process && process?.env.NODE_ENV === 'production';

Sentry.init({
    dsn: 'https://be9e5ad83d69419d96d75b83b55d8e66@o1408630.ingest.sentry.io/4505528331534336',
    tracesSampleRate: 0.01, //race all transactions.
    sampleRate: 0.01, //decrease error events
    debug: !isProd, //isProd,
    environment: isProd ? 'production' : 'develop', //env,
});
